/* eslint-disable */

import {gsap} from "gsap";

window.addEventListener('load', () => {
  ServicesBlock.init();
});

const ServicesBlock = {
  init: function () {
    const sections = document.querySelectorAll('[data-services-block-js]');

    if (!sections.length) return;

    sections.forEach((section) => {
      this.hoverImageSwap(section);
    })
  },

  hoverImageSwap: function (section) {
    let tabTriggers = section.querySelectorAll('[data-tab-trigger-js]');
    let tabs = section.querySelectorAll('[data-tab-js]');
    if (!tabTriggers && tabTriggers.length === 0 && !tabs && tabs.length === 0) return;

    tabTriggers.forEach((trigger) => {
      trigger.addEventListener('mouseover', (e) => {
        e.preventDefault();
        if (trigger.classList.contains('is-active')) return;

        let activeTrigger = section.querySelector('[data-tab-trigger-js].is-active');
        let activeTab = section.querySelector('[data-tab-js].is-active');
        let triggerIndex = trigger.getAttribute('data-tab-trigger-js');
        let newActiveTab = section.querySelector(`[data-tab-js='${triggerIndex}']`);

        if (activeTrigger) {
          activeTrigger.classList.remove('is-active');
        }
        if (activeTab) {
          activeTab.classList.remove('is-active');
        }
        if (newActiveTab) {
          newActiveTab.classList.add('is-active');
        }

        trigger.classList.add('is-active');
      })
    })
  },
}

export default ServicesBlock;
